import { DataGrid, LoadPanel } from 'devextreme-react';
import {
  useCreateLanguageMutation,
  useGetAllLanguagesQuery,
  useUpdateLanguageMutation,
} from '../../api/medapp/language.generated';
import {
  Column,
  Editing,
  FilterRow,
  HeaderFilter,
  StringLengthRule,
  LoadPanel as LoadPanelProp,
} from 'devextreme-react/data-grid';
import { cloneDeep } from 'lodash';
import { Language } from '../../api/medapp/generated';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';



export const LanguageGrid = () => {
  const { currentData: languages, isFetching: isFetchingLanguages } = useGetAllLanguagesQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const [createLanguage, { isLoading: isCreatingLanguage }] = useCreateLanguageMutation();
  const [updateLanguage, { isLoading: isUpdatingLanguage }] = useUpdateLanguageMutation();

  const create = useCallback((language: Language) => {
    createLanguage({
      code: language.code,
    });
  }, [createLanguage]);
  const { t, i18n } = useTranslation();
  const update = useCallback((e: any) => {
    
    updateLanguage({ 
      languageId: e.oldData.id, 
      languageDto: {
        code: e.newData.code
      }
    });
  }, [updateLanguage]);

  return (
    <>
      <LoadPanel
        visible={isFetchingLanguages || isCreatingLanguage || isUpdatingLanguage}
        position={{ my: 'center', at: 'center', of: `#languageDataGrid` }}
        shading={true}
        shadingColor={'rgba(0,0,0,.32)'}
        height="100%"
      />
      <DataGrid
        id="languageDataGrid"
        height={'100%'}
        width={'100%'}
        columnAutoWidth={true}
        dataSource={cloneDeep(languages)}
        onRowInserting={({ data }) => {
          create(data);
        }}
        onRowUpdating={update}
      >
        <LoadPanelProp enabled={false} />
        <Editing allowAdding={true} allowUpdating={true} />
        <FilterRow visible={true} />
        <HeaderFilter visible={true} />

        <Column
          dataField={'code'}
          caption={`${t('userNameDetailGrid.language')}`}
          allowEditing={true}
          alignment={'left'}
        >
          <StringLengthRule min={2} max={2} message={'Language length must be exact 2 letters'} />
        </Column>
      </DataGrid>
    </>
  );
};
