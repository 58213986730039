/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { LocalizedStringDTO } from './LocalizedStringDTO';

export type AdminUserWriteDTO = {
    id?: number;
    firstName?: Array<LocalizedStringDTO>;
    lastName?: Array<LocalizedStringDTO>;
    clinicIds?: Array<number>;
    specialityIds?: Array<number>;
    username: string;
    phoneNumber: string;
    emailAddress: string;
    password?: string;
    passwordTemporary?: boolean;
    profilePicture?: string;
    type?: AdminUserWriteDTO.type;
    internal?: boolean;
};

export namespace AdminUserWriteDTO {

    export enum type {
        ADMIN = 'ADMIN',
        MANAGER = 'MANAGER',
        REGISTRATOR = 'REGISTRATOR',
        SPECIALIST = 'SPECIALIST',
        ASSISTANT = 'ASSISTANT',
    }


}
