/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Comment } from './Comment';
import type { Patient } from './Patient';
import type { Schedule } from './Schedule';
import type { User } from './User';

export type Appointment = {
    id?: number;
    user?: User;
    patient?: Patient;
    schedule?: Schedule;
    patientName?: string;
    phoneNumber?: string;
    type?: Appointment.type;
    status?: Appointment.status;
    startAt?: string;
    endAt?: string;
    comments?: Array<Comment>;
};

export namespace Appointment {

    export enum type {
        INITIAL = 'INITIAL',
        SECONDARY = 'SECONDARY',
        OPEN = 'OPEN',
        INSURANCE = 'INSURANCE',
        OTHER = 'OTHER',
        ABSENCE = 'ABSENCE',
    }

    export enum status {
        SCHEDULED = 'SCHEDULED',
        PENDING = 'PENDING',
        COMPLETED = 'COMPLETED',
        CANCELLED = 'CANCELLED',
    }


}
