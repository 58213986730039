import { useCallback, useMemo } from 'react';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { DataGrid, LoadPanel } from 'devextreme-react';
import {
  Column,
  Editing,
  FilterRow,
  HeaderFilter,
  Lookup,
  MasterDetail,
  LoadPanel as LoadPanelProp,
} from 'devextreme-react/data-grid';
import { LanguageDetailGrid } from '../LanguageDetailGrid/LanguageDetailGrid';
import { updateObjectAttribute } from '../helpers';
import { useGetAllRegionsMultiLangQuery } from '../../api/medapp/region.generated';
import { useGetAllCountryMultiLangQuery } from '../../api/medapp/country.generated';
import {
  useCreateCityMutation,
  useUpdateCityMutation,
  useGetAllCityMultiLangQuery,
  useDeleteCityMutation,
} from '../../api/medapp/city.generated';
import { CityMultiLang, RegionMultiLang, CountryMultiLang, LocalizedString } from '../../api';
import { t } from 'i18next';

export const CityGrid = () => {
  const { data: allCities, isLoading: isFetchingCities } = useGetAllCityMultiLangQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const { data: allRegions, isLoading: isFetchingRegions } = useGetAllRegionsMultiLangQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const { data: allCountries, isLoading: isFetchingCountries } = useGetAllCountryMultiLangQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const [createCity, { isLoading: isCreatingCity }] = useCreateCityMutation();
  const [updateCity, { isLoading: isUpdatingCity }] = useUpdateCityMutation();
  const [deleteCity, { isLoading: isDeletingCity }] = useDeleteCityMutation();

  const { i18n } = useTranslation();
  const language = useMemo(() => i18n.language, [i18n.language]);

  const displayRegion = useCallback(
    (data: RegionMultiLang) => {
      return data.name?.find((name) => name.language === language)?.value || 'Unknown Region';
    },
    [language],
  );

  const displayCountry = useCallback(
    (data: CountryMultiLang) => {
      const country = allCountries?.find((country) => country.id === data.id);
      return country?.name?.find((name) => name.language === language)?.value || 'Unknown Country';
    },
    [allCountries, language],
  );

  const create = useCallback(
    (data: CityMultiLang) => {
      createCity({ cityMultiLangDto: data, language });
    },
    [createCity, language],
  );

  const updateCityAttribute = useCallback(
    (oldData: CityMultiLang, newData: unknown) => {
      if (oldData.id) {
        updateCity({
          cityId: oldData.id,
          cityMultiLangDto: updateObjectAttribute(oldData, newData),
        });
      }
    },
    [updateCity],
  );

  const onDelete = useCallback(
    (data: CityMultiLang) => {
      if (data.id) {
        deleteCity({ cityId: data.id });
      }
    },
    [deleteCity],
  );

  const updateCityLanguage = useCallback(
    (cityDto: CityMultiLang, oldData: LocalizedString, newData: { value: string }) => {
      const updatedCity = cloneDeep(cityDto);
      const name = updatedCity.name?.find((languages) => languages.language === oldData.language);
      if (name && cityDto.id) {
        name.value = newData.value;
        updateCity({
          cityId: cityDto.id,
          cityMultiLangDto: updatedCity,
        });
      }
    },
    [updateCity],
  );

  const detailView = useCallback(
    (city: CityMultiLang, names: LocalizedString[]) => (
      <LanguageDetailGrid
        names={names}
        onRowUpdating={(oldData, newData) => updateCityLanguage(city, oldData, newData)}
      />
    ),
    [updateCityLanguage],
  );

  return (
    
    <div style={{ position: 'relative', height: '70vh', overflow: 'auto' }}>
      <LoadPanel
        visible={isFetchingCities || isCreatingCity || isUpdatingCity || isDeletingCity || isFetchingRegions || isFetchingCountries}
        position={{ my: 'center', at: 'center', of: `#cityDataGrid` }}
        shading
        shadingColor="rgba(0,0,0,.32)"
        height={"auto"}
      />
      <DataGrid
        id="cityDataGrid"
        columnAutoWidth
        dataSource={cloneDeep(allCities ?? [])}
        onRowInserting={({ data }) => create(data)}
        onRowUpdating={({ oldData, newData }) => updateCityAttribute(oldData, newData)}
        onRowRemoving={({ data }) => onDelete(data)}
        height="100%"
      >
        <LoadPanelProp enabled={false} />
        <Editing allowAdding allowUpdating allowDeleting />
        <FilterRow visible />
        <HeaderFilter visible />
        <Column dataField="code" caption={`${t('userNameDetailGrid.Code')}`} />
        <Column dataField="regionId" caption={`${t('navigationAdmin.Region')}`}>
          <Lookup dataSource={allRegions ?? []} valueExpr="id" displayExpr={displayRegion} />
        </Column>
        <Column dataField="countryId" caption={`${t('navigationAdmin.Country')}`}>
          <Lookup dataSource={allCountries ?? []} valueExpr="id" displayExpr={displayCountry} />
        </Column>
        <MasterDetail enabled component={({ data }) => detailView(data.data, data.data.name)} />
      </DataGrid>
    </div>
  );
};
