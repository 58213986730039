import { User } from './api/medapp/generated';
import {
  CountryPage,
  HomePage,
  LanguagePage,
  RegionPage,
  CityPage,
  AddressPage,
  SchedulePage,
  ScheduleDataGridPage,
  WorkingHoursPage,
  OrganizationPage,
  UserPage,
  ClinicPage,
  SpecialitySchedulePage,
  PrivacyPage,
} from './pages';
import { TFunction } from 'i18next';

// Mapping of the routes in the Drawer menu to the components
type Route = {
  path: string;
  component: () => JSX.Element;
};

const routes: Route[] = [
  {
    path: '/home',
    component: HomePage,
  },
  {
    path: '/schedule',
    component: SchedulePage,
  },
  {
    path: '/specialitySchedule',
    component: SpecialitySchedulePage,
  },
  {
    path: '/scheduleDataGrid',
    component: ScheduleDataGridPage,
  },
  {
    path: '/workingHours',
    component: WorkingHoursPage,
  },
  {
    path: '/language',
    component: LanguagePage,
  },
  {
    path: '/country',
    component: CountryPage,
  },
  {
    path: '/region',
    component: RegionPage,
  },
  {
    path: '/city',
    component: CityPage,
  },
  {
    path: '/address',
    component: AddressPage,
  },
  {
    path: '/organization',
    component: OrganizationPage,
  },
  {
    path: '/clinic',
    component: ClinicPage,
  },
  {
    path: '/user',
    component: UserPage,
  },
  {
    path: '/privacy',
    component: PrivacyPage,
  },
];

// Define the content of the Drawer menu on the left side of the UI
interface BaseNavigationItem {
  text: string;
  icon?: string;
  roles: User.type[];
  expanded?: boolean;
  items?: BaseNavigationItem[];
}

export interface ChildNavigationItem extends BaseNavigationItem {
  path: string;
}
export interface ParentNavigationItem extends BaseNavigationItem {
  items: Array<ChildNavigationItem | ParentNavigationItem>;
}
export type NavigationType = Array<ChildNavigationItem | ParentNavigationItem>;

export function navigation(t: TFunction): NavigationType {
  return [
    {
      text: t('navigation.home'),
      path: '/home',
      icon: 'home',
      roles: [],
    },
    {
      text: t('navigation.schedule'),
      path: '/schedule',
      icon: 'event',
      roles: [User.type.MANAGER, User.type.REGISTRATOR, User.type.SPECIALIST, User.type.ASSISTANT],
    },
    {
      text: t('navigation.specialitySchedule'),
      path: '/specialitySchedule',
      icon: 'group',
      roles: [User.type.MANAGER, User.type.REGISTRATOR],
    },
    {
      text: t('navigation.scheduleDataGrid'),
      path: '/scheduleDataGrid',
      icon: 'bulletlist',
      roles: [User.type.MANAGER],
    },
    {
      text: t('navigation.workingHours'),
      path: '/workingHours',
      icon: 'clock',
      roles: [User.type.MANAGER],
    },
    {
      text: t('navigationAdmin.Geo'),
      icon: 'map',
      expanded: false,
      roles: [User.type.ADMIN],
      items: [
        { text: t('navigationAdmin.Language'), path: '/language', roles: [User.type.ADMIN] },
        { text: t('navigationAdmin.Country'), path: '/country', roles: [User.type.ADMIN] },
        { text: t('navigationAdmin.Region'), path: '/region', roles: [User.type.ADMIN] },
        { text: t('navigationAdmin.City'), path: '/city', roles: [User.type.ADMIN] },
        { text: t('navigationAdmin.Address'), path: '/address', roles: [User.type.ADMIN] },
       
      ],
    },
    {
      text:  t('navigationAdmin.Org'),
      icon: 'group',
      expanded: false,
      roles: [User.type.ADMIN],
      items: [
        { text:  t('navigationAdmin.Organization'), path: '/organization', roles: [User.type.ADMIN] },
        { text:  t('navigationAdmin.Clinic'), path: '/clinic', roles: [User.type.ADMIN] },
        { text:  t('navigationAdmin.User'), path: '/user', roles: [User.type.ADMIN] },
      ],
    },
    
    {
      text: t('navigation.user'),
      path: '/user',
      icon: 'user',
      roles: [User.type.MANAGER],
    },
  ];
}

export default routes.map((route) => {
  return {
    ...route,
    component: route.component,
  };
});
