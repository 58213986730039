import React from 'react';
import { Popup } from 'devextreme-react';
import { useCallback, useMemo, useRef } from 'react';
import Form from 'devextreme-react/form';
import { ToolbarItem } from 'devextreme-react/popup';
import { OrganizationForm } from './OrganizationForm';
import { OrganizationMultiLang } from '../../api';
import { t } from 'i18next';

export type CreateOrganizationDialogProps = {
  onClose: () => void;
  onCreate: (organization: any) => void;
};

export const CreateOrganizationDialog = (props: CreateOrganizationDialogProps) => {
  const { onClose, onCreate } = props;
  const formRef = useRef<Form>(null);

  const organization: OrganizationMultiLang = useMemo(() => {
    return {
      name: [
        { language: 'en', value: '' },
        { language: 'bg', value: '' },
      ],
      code: '',
      phoneNumber: '',
      website: '',
      mainLanguageId: 0,
      supportedLanguageIds: [],
    };
  }, []);

  const onCreateOrganization = useCallback(() =>{
      const validationResult = formRef.current?.instance.validate();
      if (validationResult?.isValid) {
        onCreate(organization);
        onClose(); 
      }
  },[onClose, onCreate, organization])

  return (
    <div id={'CreateOrganizationDialogContainer'}>
      <Popup visible={true} resizeEnabled={true} showCloseButton={false}   title={`${t('Organization.CreateOrg')}`} >
        <OrganizationForm reference={formRef} organization={organization} showTranslationsTab={true} isCreateMode={true} />
        <ToolbarItem
          widget={'dxButton'}
          toolbar={'bottom'}
          location={'after'}
          options={{
            text: `${t('buttons.confirm')}`,
            elementAttr: { 'aria-label': 'confirm' },
            onClick: onCreateOrganization
          }}
        />
        <ToolbarItem
          widget={'dxButton'}
          toolbar={'bottom'}
          location={'after'}
          options={{
            text: `${t('buttons.cancel')}`,
            elementAttr: { 'aria-label': 'cancel' },
            onClick: onClose,
          }}
        />
      </Popup>
    </div>
  );
};
