import React, { RefObject, useCallback } from 'react';
import Form, { Label, SimpleItem, Tab, TabPanelOptions, TabbedItem } from 'devextreme-react/form';
import { LanguageDetailGrid } from '../LanguageDetailGrid/LanguageDetailGrid';
import { OrganizationMultiLangDTO } from '../../api/medapp/generated';
import { LocalizedStringDto } from '../../api/medapp/clinic.generated';
import { updateObjectLanguage } from '../helpers';
import { LoadPanel, ScrollView } from 'devextreme-react';
import { useGetAllLanguagesQuery } from '../../api/medapp/language.generated';
import { t } from 'i18next';

const defaultEditorOptions = { stylingMode: 'outlined' };

export type OrganizationFormProps = {
  reference: RefObject<Form>;
  organization: OrganizationMultiLangDTO | undefined;
  showTranslationsTab?: boolean;
  isCreateMode: boolean;
};

export const OrganizationForm = (props: OrganizationFormProps) => {
  const { organization, isCreateMode } = props;

  const { currentData: languages, isFetching: isFetchingLanguages } = useGetAllLanguagesQuery(undefined);

  const createOrganizationTranslations = useCallback(
    (newData: { language: string; value: string }) => {
      if (props.organization) {
        const newTranslation = { language: newData.language, value: newData.value };
        props.organization.name = [...(props.organization.name ?? []), newTranslation];
      }
    },
    [props.organization],
  );

  const updateOrganizationTranslations = useCallback(
    (oldData: LocalizedStringDto, newData: { value: string }) => {
      updateObjectLanguage(props.organization, oldData.language, newData.value);
    },
    [props.organization],
  );

  return (
    <>
      <ScrollView>
        <LoadPanel
          visible={isFetchingLanguages}
          position={{ my: 'center', at: 'center', of: `#EditOrganizationForm` }}
          shading={true}
          shadingColor={'rgba(0,0,0,.32)'}
          height="100%"
        />
        <Form id="EditOrganizationForm" ref={props.reference} formData={props.organization}>
          <TabbedItem>
            <TabPanelOptions deferRendering={false} />
            <Tab title={`${t('AddressForm.INFO')}`} colCount={2}>
              <SimpleItem
                editorOptions={{
                  ...defaultEditorOptions,
                  disabled: !isCreateMode,
                }}
                visible={true}
                dataField="code"
              >
                <Label text={t('orgAdmin.code')} />
              </SimpleItem>
              <SimpleItem dataField={'phoneNumber'} editorOptions={defaultEditorOptions}>
                <Label text={`${t('organizationAdmin.phoneNumber')}`} />
              </SimpleItem>
              <SimpleItem dataField={'website'} editorOptions={defaultEditorOptions}>
                <Label text={`${t('organizationAdmin.website')}`} />
              </SimpleItem>
              <SimpleItem
                dataField={'mainLanguageId'}
                editorType={'dxSelectBox'}
                editorOptions={{
                  ...defaultEditorOptions,
                  items: languages,
                  displayExpr: 'code',
                  valueExpr: 'id',
                  searchEnabled: true,
                  onValueChanged: (e: any) => {
                    if (organization) {
                      organization.mainLanguageId = e.value;
                    }
                  },
                }}
              >
                <Label>{t('organizationAdmin.mainLanguageId')}</Label>
              </SimpleItem>
              <SimpleItem
                dataField={'supportedLanguageIds'}
                editorType="dxTagBox"
                editorOptions={{
                  ...defaultEditorOptions,
                  items: languages,
                  displayExpr: 'code',
                  valueExpr: 'id',
                  searchEnabled: true,
                }}
              >
                <Label>{t('organizationAdmin.supportedLanguageIds')}</Label>
              </SimpleItem>
            </Tab>
            <Tab title={`${t('AddressForm.TRANSLATIONS')}`}>
              <SimpleItem
                render={() => (
                  <LanguageDetailGrid
                    names={props.organization?.name ?? []}
                    onRowUpdating={(oldData, newData) => {
                      updateOrganizationTranslations(oldData, newData);
                    }}
                    onRowInserting={(newData) => createOrganizationTranslations(newData)}
                  />
                )}
              />
            </Tab>
          </TabbedItem>
        </Form>
      </ScrollView>
    </>
  );
};
