import { Popup } from 'devextreme-react';
import { useCallback, useMemo, useRef } from 'react';
import { useAppSelector } from '../../redux/store';
import Form from 'devextreme-react/form';
import { ToolbarItem } from 'devextreme-react/popup';
import { useDispatch } from 'react-redux';
import { ClinicMultiLang } from '../../api';
import { useCreateClinicMutation } from '../../api/medapp/clinic.generated';
import { createClinicDialogVisibleSelector, setClinicToEdit } from '../../redux/modules/clinic';
import { toggleCreateClinicDialog } from '../../redux/modules/clinic';
import { ClinicForm } from './ClinicForm';
import { useLanguage } from '../hooks/useLanguageHook';
import { t } from 'i18next';

export const CreateClinicDialog = () => {
    const formRef = useRef<Form>(null);
    const dispatch = useDispatch();
    const { language } = useLanguage();
    const clinic: ClinicMultiLang = useMemo(() => {
        return {
            name: [
                { language: 'en', value: '' },
                { language: 'bg', value: '' },
            ],
            phoneNumber: '',
            organizationId: 0,
            addressId: 0,
            countryCode: '',
            logoFileName: '',
            coverPhotoFileName: '',
        };
    }, []);

    const cancelAdd = useCallback(() => {
        dispatch(setClinicToEdit(undefined));
    }, [dispatch]);
    const showAddClinicDialog = useAppSelector(createClinicDialogVisibleSelector);

    const [createClinic] = useCreateClinicMutation();


    const finish = useCallback(() => {
        createClinic({ clinicMultiLangDto: clinic, language: language });
        dispatch(setClinicToEdit(undefined))
        dispatch(toggleCreateClinicDialog());
    }, [createClinic, dispatch, language, clinic]);

    const toggleDialog = useCallback(() => dispatch(toggleCreateClinicDialog()), [dispatch]);
    if (!showAddClinicDialog) return <div></div>;

    return (
        <div id={'CreateClinicDialogContainer'}>
            <Popup visible={showAddClinicDialog} resizeEnabled={true} showCloseButton={false} onHiding={cancelAdd}  title={`${t('Clinic.CreateClinic')}`}>
                <ClinicForm reference={formRef} clinic={clinic} showTranslationsTab={true} />
                <ToolbarItem
                    widget={'dxButton'}
                    toolbar={'bottom'}
                    location={'after'}
                    options={{
                        text: `${t('buttons.confirm')}`,
                        elementAttr: { 'aria-label': 'confirm' },
                        onClick: () => {
                            const validationResult = formRef.current?.instance.validate();
                            if (validationResult?.isValid) {
                                finish();
                            }
                        },
                    }}
                />

                <ToolbarItem
                    widget={'dxButton'}
                    toolbar={'bottom'}
                    location={'after'}
                    options={{
                        text: `${t('buttons.cancel')}`,
                        elementAttr: { 'aria-label': 'cancel' },
                        onClick: () => toggleDialog(), 
                    }}
                />
            </Popup>
        </div>
    );
};
