import { LoadPanel, Popup, ScrollView } from 'devextreme-react';
import { useCallback, useRef } from 'react';
import { useAppSelector } from '../../redux/store';
import { clinicToEditSelector, setClinicToEdit } from '../../redux/modules/clinic';
import Form from 'devextreme-react/form';
import { ToolbarItem } from 'devextreme-react/popup';
import { useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import { useUpdateClinicMutation } from '../../api/medapp/clinic.generated';
import { ClinicMultiLangDTO } from '../../api/medapp/generated';
import { ClinicForm } from './ClinicForm';
import { t } from 'i18next';

export const EditClinicDialog = () => {
  const formRef = useRef<Form>(null);
  const dispatch = useDispatch();

  const clinicToEdit = cloneDeep(useAppSelector(clinicToEditSelector));

  const cancelEditing = useCallback(() => {
    dispatch(setClinicToEdit(undefined));
  }, [dispatch]);

  const [updateClinic, { isLoading }] = useUpdateClinicMutation();

  const finish = useCallback(() => {
    if (clinicToEdit?.id) {
      updateClinic({
        clinicId: clinicToEdit.id,
        clinicMultiLangDto: clinicToEdit,
      });
      dispatch(setClinicToEdit(undefined));
    }
  }, [clinicToEdit, updateClinic, dispatch]);



  if (!clinicToEdit) return null;

  return (
    <div id="EditClinicDialogContainer">
      <Popup
        visible={!!clinicToEdit}
        resizeEnabled={true}
        showCloseButton={false}   
        onHiding={cancelEditing}
        title={`${t('Clinic.CreateClinic')}`}
      >
        <ScrollView>
          {isLoading && (
            <LoadPanel
              visible={true}
              shading={true}
              shadingColor={'rgba(0,0,0,.32)'}
              height="100%"
              position={{ my: 'center', at: 'center', of: `#EditClinicDialogContainer` }}
            />
          )}
          <ClinicForm
            reference={formRef}
            clinic={clinicToEdit as ClinicMultiLangDTO}
            showTranslationsTab={true}
          />
        </ScrollView>
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={{
            text: `${t('buttons.confirm')}`,
            onClick: () => {
              const validationResult = formRef.current?.instance.validate();
              if (validationResult?.isValid) {
                finish();
              }
            },
          }}
        />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={{
            text: `${t('buttons.cancel')}`,
            onClick: cancelEditing,
          }}
        />
      </Popup>
    </div>
  );
};
