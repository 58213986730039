/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { NextAvailableSlotDTO } from './NextAvailableSlotDTO';

export type UserInfoDTO = {
    userId?: number;
    scheduleStart?: string;
    scheduleEnd?: string;
    usualInitialAppointmentDuration?: number;
    usualSecondaryAppointmentDuration?: number;
    daysRemaining?: number;
    userStatus?: UserInfoDTO.userStatus;
    userType?: UserInfoDTO.userType;
    workingDayStartTime?: string;
    workingDayEndTime?: string;
    breakStartTime?: string;
    breakEndTime?: string;
    dayOfWeek?: UserInfoDTO.dayOfWeek;
    nextWorkingDay?: string;
    nextDayOff?: string;
    nextAppointment?: string;
    nextAvailableSlot?: NextAvailableSlotDTO;
    allAppointments?: number;
    appointmentsRemaining?: number;
};

export namespace UserInfoDTO {

    export enum userStatus {
        WORKING = 'WORKING',
        ON_BREAK = 'ON_BREAK',
        NOT_WORKING = 'NOT_WORKING',
        ABSENCE = 'ABSENCE',
    }

    export enum userType {
        ADMIN = 'ADMIN',
        MANAGER = 'MANAGER',
        REGISTRATOR = 'REGISTRATOR',
        SPECIALIST = 'SPECIALIST',
        ASSISTANT = 'ASSISTANT',
    }

    export enum dayOfWeek {
        MONDAY = 'MONDAY',
        TUESDAY = 'TUESDAY',
        WEDNESDAY = 'WEDNESDAY',
        THURSDAY = 'THURSDAY',
        FRIDAY = 'FRIDAY',
        SATURDAY = 'SATURDAY',
        SUNDAY = 'SUNDAY',
    }


}
