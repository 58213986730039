import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { DataGrid, LoadPanel, ScrollView } from 'devextreme-react';
import {
  LocalizedStringDto,
  useCreateRegionMutation,
  useDeleteRegionMutation,
  useGetAllRegionsMultiLangQuery,
  useUpdateRegionMutation,
} from '../../api/medapp/region.generated';
import {
  Column,
  FilterRow,
  HeaderFilter,
  Lookup,
  MasterDetail,
  LoadPanel as LoadPanelProp,
} from 'devextreme-react/data-grid';
import { Editing } from 'devextreme-react/data-grid';
import { updateObjectAttribute, updateObjectLanguage } from '../helpers';
import { LanguageDetailGrid } from '../LanguageDetailGrid/LanguageDetailGrid';
import { useGetAllCountryQuery } from '../../api/medapp/country.generated';
import { CountryMultiLang } from '../../api';
import { RegionMultiLangDto } from '../../api/medapp/region.generated';
import { t } from 'i18next';

export const RegionGrid = () => {
  const { i18n } = useTranslation();
  const language = useMemo(() => i18n.language, [i18n.language]);

  const { currentData: allRegions, isFetching: isFetchingRegions } = useGetAllRegionsMultiLangQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const { currentData: allCountries, isFetching: isFetchingCountries } = useGetAllCountryQuery(
    { language },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const [createRegion, { isLoading: isCreatingRegion }] = useCreateRegionMutation();
  const [updateRegion, { isLoading: isUpdatingRegion }] = useUpdateRegionMutation();
  const [deleteRegion, { isLoading: isDeletingRegion }] = useDeleteRegionMutation();

  const create = useCallback(
    (data: RegionMultiLangDto) => {
      createRegion({ regionMultiLangDto: data });
    },
    [createRegion],
  );

  const displayCountry = useCallback(
    (data: CountryMultiLang) => {
      const countryName = data?.name?.find((name) => name.language === language)?.value;
      return countryName ?? 'Unknown';
    },
    [language],
  );

  const updateRegionAttribute = useCallback(
    (oldData: RegionMultiLangDto, newData: Partial<RegionMultiLangDto>) => {
      if (oldData.regionId) {
        updateRegion({
          regionId: oldData.regionId,
          regionMultiLangDto: updateObjectAttribute(oldData, newData),
        });
      }
    },
    [updateRegion],
  );

  const updateRegionLanguage = useCallback(
    (region: RegionMultiLangDto, oldData: LocalizedStringDto, newData: { value: string }) => {
      if (region.regionId) {
        updateRegion({
          regionId: region.regionId,
          regionMultiLangDto: updateObjectLanguage(cloneDeep(region), oldData.language, newData.value),
        });
      }
    },
    [updateRegion],
  );

  const detailView = useCallback(
    (region: RegionMultiLangDto, names: LocalizedStringDto[]) => (
      <LanguageDetailGrid
        names={names ?? []}
        onRowUpdating={(oldData, newData) => updateRegionLanguage(region, oldData, newData)}
      />
    ),
    [updateRegionLanguage],
  );

  const onDelete = useCallback(
    (data: RegionMultiLangDto) => {
      if (data.regionId) {
        deleteRegion({ regionId: data.regionId });
      }
    },
    [deleteRegion],
  );

  return (
    <div style={{ position: 'relative', height: '80vh', overflow: 'auto' }}>
      {/* Scrollable Load Panel */}
      <LoadPanel
        visible={isFetchingRegions || isCreatingRegion || isUpdatingRegion || isDeletingRegion || isFetchingCountries}
        position={{ my: 'center', at: 'center', of: `#regionDataGrid` }}
        shading
        shadingColor="rgba(0,0,0,.32)"
        height={'auto'} // Ensures it doesn't cover the full screen
        width={300}
      >
        <ScrollView height={150}>
          <div style={{ padding: '10px' }}>Loading data, please wait...</div>
        </ScrollView>
      </LoadPanel>

      <DataGrid
        id="regionDataGrid"
        columnAutoWidth
        dataSource={cloneDeep(allRegions ?? [])}
        onRowInserting={({ data }) => create(data)}
        onRowUpdating={({ oldData, newData }) => updateRegionAttribute(oldData, newData)}
        onRowRemoving={({ data }) => onDelete(data)}
        height="100%"
      >
        <Editing allowAdding allowUpdating allowDeleting />
        <FilterRow visible />
        <HeaderFilter visible />

        <Column dataField="code" caption={t('regionAdmin.Regioncode')} />
        <Column dataField="countryId" caption={t('regionAdmin.Country')}>
          <Lookup dataSource={allCountries ?? []} valueExpr="id" displayExpr={displayCountry} />
        </Column>

        <MasterDetail enabled component={({ data }) => detailView(data.data, data.data.name)} />
      </DataGrid>
    </div>
  );
};
