import React, { useCallback, useRef } from 'react';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import { t } from 'i18next';
import { OrganizationForm } from './OrganizationForm';
import { OrganizationMultiLang } from '../../api';

interface EditOrganizationDialogProps {
  onEdit: (organization: any) => void;
  onClose: () => void;
  organization: OrganizationMultiLang | undefined;
}

export const EditOrganizationDialog = (props: EditOrganizationDialogProps) => {
  const { onEdit, onClose, organization } = props;
  const formRef = useRef<Form>(null);

  const cancelEditing = useCallback(() => {
    onClose();
  }, [onClose]);

  const onEditOrganization = useCallback(() => {
    const validationResult = formRef.current?.instance.validate();
    if (validationResult?.isValid) {
      onEdit(organization);
      onClose();
    }
  }, [onClose, onEdit, organization]);

  return (
    <Popup
      visible={true}
      resizeEnabled={true}
      title={`${t('Organization.EditOrg')}`}
    >
      <OrganizationForm
        reference={formRef}
        organization={organization}
        showTranslationsTab={true}
        isCreateMode={false}
      />

      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        options={{
          text: t('buttons.confirm'),
          elementAttr: { 'aria-label': 'confirm' },
          onClick: onEditOrganization,
        }}
      />

      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        options={{
          text: t('buttons.cancel'),
          elementAttr: { 'aria-label': 'cancel' },
          onClick: cancelEditing,
        }}
      />
    </Popup>
  );
};
