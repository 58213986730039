import React, { useCallback, useRef, useState } from 'react';
import { DataGrid, LoadPanel } from 'devextreme-react';
import {
  Column,
  FilterRow,
  HeaderFilter,
  Toolbar,
  Item,
  Selection,
  LoadPanel as LoadPanelProp,
} from 'devextreme-react/data-grid';
import { EditOrganizationDialog } from './EditOrganizationDialog';
import infoDialog from '../dialogs/InfoDialog';
import DataSource from 'devextreme/data/data_source';
import { OrganizationMultiLang } from '../../api';
import {
  useCreateOrganizationMutation,
  useDeleteOrganizationMutation,
  useGetAllOrganizationsMultiLangQuery,
  useUpdateOrganizationMutation,
} from '../../api/medapp/organization.generated';
import { CreateOrganizationDialog } from './AddOrganizationDialog';

import { useTranslation } from 'react-i18next';
import { useLanguage } from '../hooks/useLanguageHook';
import { cloneDeep } from 'lodash';
import { custom as customDialog } from 'devextreme/ui/dialog';

export const OrganizationGrid = () => {
  const organizationGridRef = useRef<DataGrid<OrganizationMultiLang, string>>(null);
  const { t } = useTranslation();

  const { language } = useLanguage();
  const { currentData: allOrganizations, isFetching: isFetchingOrganizations } = useGetAllOrganizationsMultiLangQuery();

  const [isVisibleCreateDialog, setIsVisibleCreateDialog] = useState(false);
  const [isVisibleEditDialog, setIsVisibleEditDialog] = useState(false);
  const [organizationToEdit, setOrganizationToEdit] = useState<OrganizationMultiLang>();

  const [deleteOrganization] = useDeleteOrganizationMutation();
  const [createOrganization] = useCreateOrganizationMutation();
  const [updateOrganization] = useUpdateOrganizationMutation();

  const onCloseCreateOrganizationDialog = useCallback(() => {
    setIsVisibleCreateDialog(false);
  }, []);

  const onOpenCreateOrganizationDialog = useCallback(() => {
    setIsVisibleCreateDialog(true);
  }, []);

  const onOpenEditOrganizationDialog = useCallback(() => {
    const selectedItems = organizationGridRef.current?.instance.getSelectedRowsData();
    if (!selectedItems || selectedItems.length === 0) {
      infoDialog({ message: 'No entry is selected' });
      return;
    }
    if (selectedItems.length > 1) {
      infoDialog({ message: 'Cannot select multiple entries' });
      return;
    }
    setOrganizationToEdit(selectedItems?.[0]);
    setIsVisibleEditDialog(true);
  }, []);

  const onCloseEditOrganizationDialog = useCallback(() => {
    setOrganizationToEdit(undefined);
    setIsVisibleEditDialog(false);
  }, []);

  const onCreateOrganization = useCallback(
    (organization: OrganizationMultiLang) => {
      createOrganization({ organizationMultiLangDto: organization, language: language });
    },
    [createOrganization, language],
  );

  const onEditOrganization = useCallback(
    (organization: OrganizationMultiLang) => {
      organization.id &&
        updateOrganization({ organizationId: organization.id, organizationMultiLangDto: organization });
      setOrganizationToEdit(undefined);
    },
    [updateOrganization],
  );

  const onDelete = useCallback(
    (selectedItems: OrganizationMultiLang[] | undefined): void => {
      if (!selectedItems || selectedItems.length === 0) {
        infoDialog({ message: "No entry is selected" });
        return;
      }
  
      selectedItems
        .filter(Boolean) // Remove undefined/null values
        .forEach((organization) => {
          const organizationId = organization?.id;
          if (typeof organizationId === 'number') {
            const confirmDialog = customDialog({
              title: "Confirm Deletion",
              messageHtml: "Are you sure you want to delete this organization?",
              buttons: [
                {
                  text: "Yes",
                  onClick: () => {
                    deleteOrganization({ organizationId }); 
                    return true;
                  },
                },
                {
                  text: "No",
                  onClick: () => false,
                },
              ],
            });
            confirmDialog.show();
          }
        });
    },
    [deleteOrganization]
  );
  
  return (
    <>
      {isVisibleCreateDialog && (
        <CreateOrganizationDialog onClose={onCloseCreateOrganizationDialog} onCreate={onCreateOrganization} />
      )}

      {isVisibleEditDialog && (
        <EditOrganizationDialog
          onEdit={onEditOrganization}
          onClose={onCloseEditOrganizationDialog}
          organization={cloneDeep(organizationToEdit)}
        />
      )}

      <LoadPanel
        visible={isFetchingOrganizations}
        position={{ my: 'center', at: 'center', of: `#organizationDataGrid` }}
        shading={true}
        shadingColor={'rgba(0,0,0,.32)'}
        height="100%"
      />
      <DataGrid
        ref={organizationGridRef}
        id={'organizationDataGrid'}
        height={'100%'}
        width={'100%'}
        columnAutoWidth={true}
        repaintChangesOnly={true}
        dataSource={new DataSource(allOrganizations ?? [])}
      >
        <LoadPanelProp enabled={false} />
        <Selection mode={'multiple'} />
        <FilterRow visible={true} />
        <HeaderFilter visible={true} />
        <Toolbar>
          <Item
            widget={'dxButton'}
            visible={true}
            options={{
              icon: 'plus',
              onClick: onOpenCreateOrganizationDialog,
            }}
            location={'after'}
          />
          <Item
            widget={'dxButton'}
            visible={true}
            options={{
              icon: 'edit',
              onClick: onOpenEditOrganizationDialog,
            }}
            location={'after'}
          />
          <Item
            widget="dxButton"
            visible={true}
            options={{
              icon: 'trash',
              onClick: () => {
                onDelete(organizationGridRef.current?.instance.getSelectedRowsData());
              },
            }}
            location="after"
          />
        </Toolbar>
        <Column dataField="code" caption={t('orgAdmin.code')} />
        <Column dataField={'phoneNumber'} caption={`${t('createUserDialog.phone')}`} />
        <Column dataField={'website'} caption={`${t('organizationAdmin.website')}`} />
      </DataGrid>
    </>
  );
};
