/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AppointmentDTO = {
    id?: number;
    userId: number;
    patientId?: number;
    patientName?: string;
    phoneNumber?: string;
    status: AppointmentDTO.status;
    appointmentType: AppointmentDTO.appointmentType;
    startAt: string;
    endAt?: string;
};

export namespace AppointmentDTO {

    export enum status {
        SCHEDULED = 'SCHEDULED',
        PENDING = 'PENDING',
        COMPLETED = 'COMPLETED',
        CANCELLED = 'CANCELLED',
    }

    export enum appointmentType {
        INITIAL = 'INITIAL',
        SECONDARY = 'SECONDARY',
        OPEN = 'OPEN',
        INSURANCE = 'INSURANCE',
        OTHER = 'OTHER',
        ABSENCE = 'ABSENCE',
    }


}
