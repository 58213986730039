/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UserDTO = {
    id?: number;
    firstName?: string;
    lastName?: string;
    username?: string;
    clinicIds?: Array<number>;
    specialityIds?: Array<number>;
    phoneNumber?: string;
    emailAddress?: string;
    address?: string;
    profilePicture?: string;
    type?: UserDTO.type;
};

export namespace UserDTO {

    export enum type {
        ADMIN = 'ADMIN',
        MANAGER = 'MANAGER',
        REGISTRATOR = 'REGISTRATOR',
        SPECIALIST = 'SPECIALIST',
        ASSISTANT = 'ASSISTANT',
    }


}
