import { Popup } from 'devextreme-react';
import { useCallback, useRef, useState } from 'react';
import { useAppSelector } from '../../redux/store';
import Form from 'devextreme-react/form';
import { ToolbarItem } from 'devextreme-react/popup';
import { useDispatch } from 'react-redux';
import { AddressMultiLang } from '../../api';
import { useCreateAddressMutation } from '../../api/medapp/address.generated';
import { createAddressDialogVisibleSelector, toggleCreateAddressDialog } from '../../redux/modules/address';
import { AddressForm } from './AddressForm';
import LoadPanel from 'devextreme-react/load-panel';
import { t } from 'i18next';

export const CreateAddressDialog = () => {
  const formRef = useRef<Form>(null);
  const dispatch = useDispatch();

  const [newAddress] = useState<AddressMultiLang>({
    name: [
      { language: 'en', value: '' },
      { language: 'bg', value: '' },
    ],
    postalCode: '',
    notes: [
      { language: 'en', value: '' },
      { language: 'bg', value: '' },
    ],
    countryId: 0,
    cityId: 0,
    regionId: 0,
  });

  const showAddAddressDialog = useAppSelector(createAddressDialogVisibleSelector);
  const [createAddress, { isLoading }] = useCreateAddressMutation();

  const toggleDialog = useCallback(() => dispatch(toggleCreateAddressDialog()), [dispatch]);

  const finish = useCallback(() => {
    createAddress({ addressMultiLangDto: newAddress })
      .unwrap()
      .then(() => {
        toggleDialog();
      });
  }, [createAddress, newAddress, toggleDialog]);

  if (!showAddAddressDialog) return null;

  return (
    <Popup visible={true} resizeEnabled={true} showCloseButton={false} title={`${t('AddressForm.CreateAddress')}`}>
      {isLoading && <LoadPanel visible={true} />}

      <AddressForm reference={formRef} address={newAddress} showTranslationsTab={true} />

      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        options={{
          text: t('buttons.confirm'),
          onClick: () => {
            const validationResult = formRef.current?.instance.validate();
            if (validationResult?.isValid) {
              finish();
            }
          },
        }}
      />
      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        options={{
          text: t('buttons.cancel'),
          onClick: toggleDialog,
        }}
      />
    </Popup>
  );
};
