import Form, { Label, SimpleItem, Tab, TabPanelOptions, TabbedItem } from 'devextreme-react/form';
import { LanguageDetailGrid } from '../LanguageDetailGrid/LanguageDetailGrid';
import { LocalizedStringDto } from '../../api/medapp/clinic.generated';
import { updateObjectLanguage } from '../helpers';
import { LoadPanel, ScrollView, SelectBox } from 'devextreme-react';
import { AddressMultiLang, CityMultiLang } from '../../api';
import { useGetAllOrganizationsMultiLangQuery } from '../../api/medapp/organization.generated';
import { useGetAllAddressesMultilangQuery } from '../../api/medapp/address.generated';
import { useGetAllCityMultiLangQuery } from '../../api/medapp/city.generated';
import { RefObject, useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ClinicMultiLangDTO } from '../../api/medapp/generated';
import { t } from 'i18next';

const defaultEditorOptions = { stylingMode: 'outlined' };

export type ClinicFormProps = {
  reference: RefObject<Form>;
  clinic: ClinicMultiLangDTO;
  showTranslationsTab?: boolean;
};

export const ClinicForm = (props: ClinicFormProps) => {
  const { i18n } = useTranslation();
  const language = useMemo(() => i18n.language, [i18n.language]);

  const [addressId, setAddressId] = useState<number | null>(props.clinic.addressId || null);
  const [organizationId, setOrganizationId] = useState<number | null>(props.clinic.organizationId || null);

  const { currentData: allOrganizations, isFetching: isFetchingOrganizations } = useGetAllOrganizationsMultiLangQuery();
  const { currentData: allAddresses, isFetching: isFetchingAddresses } = useGetAllAddressesMultilangQuery();
  const { currentData: allCities, isFetching: isFetchingCities } = useGetAllCityMultiLangQuery();

  const updateClinicTranslations = useCallback((oldData: LocalizedStringDto, newData: { value: string }) => {
    updateObjectLanguage(props.clinic, oldData.language, newData.value);
  }, [props.clinic]);

  const createClinicTranslations = useCallback((newData: { language: string; value: string }) => {
    const newTranslation = { language: newData.language, value: newData.value };
    props.clinic.name = [...(props.clinic.name ?? []), newTranslation];
  }, [props.clinic]);

  const calculateAddressName = useCallback((data: AddressMultiLang) => {
    if (!data) return '';
    const addressName = data?.name?.find((name) => name.language === language)?.value;
    const cityName = allCities
      ?.find((city: CityMultiLang) => city.id === data?.cityId)
      ?.name?.find((name) => name.language === language)?.value;

    return `${cityName || ''}, ${addressName || ''}`.trim();
  }, [language, allCities]);

  return (
    <>
      <ScrollView>
        <LoadPanel
          visible={isFetchingAddresses || isFetchingOrganizations || isFetchingCities}
          position={{ my: 'center', at: 'center', of: `#EditClinicForm` }}
          shading={true}
          shadingColor={'rgba(0,0,0,.32)'}
          height="100%"
        />
        <Form id="EditClinicForm" ref={props.reference} formData={props.clinic}>
          <TabbedItem>
            <TabPanelOptions deferRendering={false} />
            <Tab title={`${t('AddressForm.INFO')}`} colCount={2}>
              <SimpleItem dataField="phoneNumber" editorOptions={defaultEditorOptions}>
                <Label text={`${t('organizationAdmin.phoneNumber')}`} />
              </SimpleItem>
              <SimpleItem
                dataField={`${t('addressGrid.address')}`}
                editorOptions={defaultEditorOptions}
                render={() => (
                  <SelectBox
                    dataSource={allAddresses || []}
                    valueExpr="id"
                    value={addressId}
                    displayExpr={(data: AddressMultiLang) => calculateAddressName(data)}
                    onValueChanged={({ value }) => {
                      setAddressId(value);
                      props.clinic.addressId = value;
                    }}
                  />
                )}
              />
              <SimpleItem
                dataField={`${t('organizationAdmin.organization')}`}
                editorOptions={defaultEditorOptions}
                render={() => (
                  <SelectBox
                    dataSource={allOrganizations || []}
                    valueExpr="id"
                    value={organizationId}
                    displayExpr={(data) => data?.name?.find((name: any) => name.language === language)?.value || ''}
                    onValueChanged={({ value }) => {
                      setOrganizationId(value);
                      props.clinic.organizationId = value;
                    }}
                  />
                )}
              />
            </Tab>
            {props.showTranslationsTab && (
              <Tab title={`${t('AddressForm.TRANSLATIONS')}`}>
                <SimpleItem
                  render={() => (
                    <LanguageDetailGrid
                      names={props.clinic.name ?? []}
                      onRowUpdating={updateClinicTranslations}
                      onRowInserting={createClinicTranslations}
                    />
                  )}
                />
              </Tab>
            )}
          </TabbedItem>
        </Form>
      </ScrollView>
    </>
  );
};
