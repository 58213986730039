import Form, { Label, SimpleItem, Tab, TabPanelOptions, TabbedItem } from 'devextreme-react/form';
import { LanguageDetailGrid } from '../LanguageDetailGrid/LanguageDetailGrid';
import { LocalizedStringDto } from '../../api/medapp/clinic.generated';
import { getTranslatedValue } from '../helpers';
import { LoadPanel, ScrollView, SelectBox } from 'devextreme-react';
import { AddressMultiLangDTO } from '../../api/medapp/generated';
import { useGetAllCountryMultiLangQuery } from '../../api/medapp/country.generated';
import { useGetAllCityMultiLangQuery } from '../../api/medapp/city.generated';
import { RefObject, useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const defaultEditorOptions = { stylingMode: 'outlined' };

export type AddressFormProps = {
  reference: RefObject<Form>;
  address: AddressMultiLangDTO;
  showTranslationsTab?: boolean;
};

export const AddressForm = (props: AddressFormProps) => {
  const { address } = props;
  const { t, i18n } = useTranslation();
  const language = useMemo(() => i18n.language, [i18n.language]);
  const [countryId, setCountryId] = useState(props.address.countryId || null);

  const [cityId, setCityId] = useState(props.address.cityId || null);

  const { currentData: allCountry, isFetching: isFetchingCountries } = useGetAllCountryMultiLangQuery();
  const { currentData: allCities, isFetching: isFetchingCities } = useGetAllCityMultiLangQuery();
  const { showTranslationsTab = true } = props;

  const createAddressTranslations = useCallback(
    (newData: { language: string; value: string }) => {
      const newTranslation = { language: newData.language, value: newData.value };
      address.name = [...(address.name ?? []), newTranslation];
    },
    [address.name],
  );

  const updateAddressTranslations = useCallback(
    (oldData: LocalizedStringDto, newData: { value: string }) => {
      const updatedNames = address?.name.map((translation) =>
        translation.language === oldData.language ? { ...translation, value: newData.value } : translation,
      );
      return updatedNames;
    },
    [address?.name],
  );

  const countryRenderer = useCallback(() => {
    return (
      <SelectBox
        dataSource={allCountry ?? []}
        valueExpr="id"
        displayExpr={(data) => (data ? getTranslatedValue(data?.name ?? [], language) : '')}
        value={address.countryId}
        onValueChanged={({ value }) => {
          if (address && value) {
            setCountryId(value);
            address.countryId = value;
          }
        }}
      />
    );
  }, [allCountry, address, language]);

  const cityRenderer = useCallback(() => {

    return (
      <SelectBox
        dataSource={allCities ?? []}
        valueExpr="id"
        displayExpr={(data) => (data ? getTranslatedValue(data?.name ?? [], language) : '')}
        value={address.cityId}
        onValueChanged={({ value }) => {
          if (address && value) {
            setCityId(value);
            address.cityId = value;
          }
        }}
      />
    );
  }, [allCities, address, language]);

  return (
    <>
      <ScrollView>
        <LoadPanel
          visible={isFetchingCities || isFetchingCountries}
          position={{ my: 'center', at: 'center', of: `#EditAddressForm` }}
          shading={true}
          shadingColor="rgba(0,0,0,.32)"
          height="100%"
        />
        <Form id="EditAddressForm" ref={props.reference} formData={props.address}>
          <TabbedItem>
            <TabPanelOptions deferRendering={false} />

            <Tab title={`${t('AddressForm.INFO')}`} colCount={2}>
              <SimpleItem dataField={'countryId'} render={countryRenderer}>
                <Label text={`${t('organizationAdmin.mainLanguageId')}`} />
              </SimpleItem>
              <SimpleItem dataField={'cityId'} render={cityRenderer}>
                <Label text={`${t('organizationAdmin.cityId')}`} />
              </SimpleItem>
              <SimpleItem dataField={'apartmentNumber'} editorOptions={defaultEditorOptions}>
                <Label text={`${t('organizationAdmin.apartmentNumber')}`} />
              </SimpleItem>
              <SimpleItem dataField={'floorNumber'} editorOptions={defaultEditorOptions}>
                <Label text={`${t('organizationAdmin.floorNumber')}`} />
              </SimpleItem>
              <SimpleItem dataField={'latitude'} editorOptions={defaultEditorOptions}>
                <Label text={`${t('organizationAdmin.latitude')}`} />
              </SimpleItem>
              <SimpleItem dataField={'longitude'} editorOptions={defaultEditorOptions}>
                <Label text={`${t('organizationAdmin.longitude')}`} />
              </SimpleItem>
              <SimpleItem dataField={'postalCode'} editorOptions={defaultEditorOptions}>
                <Label text={`${t('organizationAdmin.postalCode')}`} />
              </SimpleItem>
              <SimpleItem dataField={'streetNumber'} editorOptions={defaultEditorOptions}>
                <Label text={`${t('addressGrid.streetNumber')}`} />
              </SimpleItem>
            </Tab>

            {showTranslationsTab && (
              <Tab title={`${t('AddressForm.TRANSLATIONS')}`}>
                <SimpleItem
                  render={() => (
                    <LanguageDetailGrid
                      names={props.address?.name ?? []}
                      onRowUpdating={(oldData, newData) => updateAddressTranslations(oldData, newData)}
                      onRowInserting={(newData) => createAddressTranslations(newData)}
                    />
                  )}
                />
              </Tab>
            )}
          </TabbedItem>
        </Form>
      </ScrollView>
    </>
  );
};
