import { enhancedApi } from './region.generated';

export const registerEnhancedRegionEndpoints = () => {
  enhancedApi.enhanceEndpoints({
    endpoints: {
      getAllRegionsMultiLang: {
        providesTags: () => ['Region'],
      },
      createRegion: {
        invalidatesTags: ['Region'],
      },
      updateRegion: {
        invalidatesTags: ['Region'],
      },
      deleteRegion: {
        invalidatesTags: ['Region'],
      },
    },
  });
};
