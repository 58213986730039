import { DataGrid } from 'devextreme-react';
import { useCallback, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useGetAllAddressesMultilangQuery } from '../../api/medapp/address.generated';
import { useGetAllCityMultiLangQuery } from '../../api/medapp/city.generated';
import {
  FilterRow,
  HeaderFilter,
  Toolbar,
  Item,
  Selection,
  LoadPanel as LoadPanelProp,
  Column,
} from 'devextreme-react/data-grid';
import { AddressMultiLang, CityMultiLang } from '../../api';
import { CreateAddressDialog } from './CreateAddressDialog';
import { toggleCreateAddressDialog, setAddressToEdit, createAddressDialogVisibleSelector } from '../../redux/modules/address';
import { getTranslatedValue } from '../helpers';
import { useTranslation } from 'react-i18next';
import { EditAddressDialog } from './EditAddressDialog';
import { useAppSelector } from '../../redux/store';
import { t } from 'i18next';


export const AddressGrid = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const addressGridRef = useRef<DataGrid<AddressMultiLang>>(null);
  const showAddAddressDialog = useAppSelector(createAddressDialogVisibleSelector);
  const language = useMemo(() => i18n.language, [i18n.language]);

  const { currentData: allAddresses, isFetching: isFetchingAddresses } = useGetAllAddressesMultilangQuery();
  const { currentData: allCities, isFetching: isFetchingCities } = useGetAllCityMultiLangQuery();

  const onCreate = useCallback(() => {
    dispatch(toggleCreateAddressDialog());
  }, [dispatch]);

  const onEdit = useCallback(() => {
    const selectedItems = addressGridRef.current?.instance.getSelectedRowsData();
    if (!selectedItems || selectedItems.length === 0) {
      alert('No entry is selected');
      return;
    }
    if (selectedItems.length > 1) {
      alert('Editing multiple addresses is currently not supported');
      return;
    }
    dispatch(setAddressToEdit(selectedItems[0]));
  }, [dispatch]);

  const calculateCityDisplayExpr = useCallback(
    (data: CityMultiLang) => {
      return getTranslatedValue(data.name ?? [], language) ?? '';
    },
    [language],
  );

  const calculateStreetCellValue = useCallback(
    (data: AddressMultiLang) => {
      return getTranslatedValue(data.name ?? [], language);
    },
    [language],
  );

  const calculateCityCellValue = useCallback(
    (rowData: { cityId: number | undefined }) => {
      const city = allCities?.find((city) => city.id === rowData.cityId) ?? { name: [], countryId: 0 };
      return calculateCityDisplayExpr(city);
    },
    [allCities, calculateCityDisplayExpr],
  );

  return (
    <>
     
      {showAddAddressDialog &&  ( <CreateAddressDialog />) }
      <EditAddressDialog />
      <DataGrid
        ref={addressGridRef}
        id="addressDataGrid"
        height="100%"
        width="100%"
        columnAutoWidth
        repaintChangesOnly
        dataSource={allAddresses || []}

      >
        <LoadPanelProp enabled={isFetchingAddresses || isFetchingCities} />
        <Selection mode="multiple" />
        <FilterRow visible />
        <HeaderFilter visible />
        <Toolbar>
          <Item
            widget="dxButton"
            options={{
              icon: 'plus',
              onClick: onCreate,
            }}
            location="after"
          />
          <Item
            widget="dxButton"
            options={{
              icon: 'edit',
              onClick: onEdit,
            }}
            location="after"
          />
        </Toolbar>
        <Column
          dataField="city"
         
          caption={`${t('addressGrid.city')}`}
          calculateCellValue={calculateCityCellValue}
        />
        <Column
          dataField="street"
          caption={`${t('addressGrid.street')}`}
          calculateCellValue={calculateStreetCellValue}
        />
        <Column dataField="streetNumber" caption={`${t('addressGrid.streetNumber')}`} />
      </DataGrid>
    </>
  );
};
