import { useCallback, useMemo, useState } from 'react';
import { Button, DataGrid, LoadPanel, Popup, ScrollView } from 'devextreme-react';
import {
  useCreateScheduleMutation,
  useUpdateScheduleMutation,
  useGetAllSchedulesQuery,
  ScheduleDto,
  useDeleteScheduleMutation,
  useGetScheduleDetailsQuery,
  ObjectRevisionDto,
} from '../../api/medapp/schedule.generated';

import { useGetAllUserMultiLangQuery, useGetClinicsForCurrentUserQuery } from '../../api/medapp/user.generated';
import { cloneDeep } from 'lodash';
import { Column, Lookup, LoadPanel as LoadPanelProp, FilterRow } from 'devextreme-react/data-grid';
import { Editing } from 'devextreme-react/scheduler';
import { getTranslatedValue, renderProfileImage } from '../helpers';
import { UserMultilang } from '../../api';
import { useLanguage } from '../hooks/useLanguageHook';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTimeFormatOptions } from 'luxon';
import { LocalizedStringDto } from '../../api/medapp/clinic.generated';

const dateTimeOptions: DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

export const ScheduleDataGrid = () => {
  const { language } = useLanguage();
  const { t } = useTranslation();

  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [scheduleId, setScheduleId] = useState(0);

  const { currentData: scheduleRevision, isFetching: isFetchingScheduleRevision } = useGetScheduleDetailsQuery(
    {
      scheduleId: scheduleId,
      language: language,
    },
    { skip: scheduleId === 0, refetchOnMountOrArgChange: true },
  );

  const { currentData: allSchedules, isFetching: isFetchingSchedules } = useGetAllSchedulesQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const [createSchedule, { isLoading: isCreatingSchedule }] = useCreateScheduleMutation();
  const [updateSchedule, { isLoading: isUpdatingSchedule }] = useUpdateScheduleMutation();
  const [deleteSchedule, { isLoading: isDeletingSchedule }] = useDeleteScheduleMutation();

  const { currentData: allUsers, isFetching: isFetchingUsers } = useGetAllUserMultiLangQuery(
    { userType: 'SPECIALIST' },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const { currentData: allClinicsForCurrentUser, isFetching: isFetchingClinicsForCurrentUser } =
    useGetClinicsForCurrentUserQuery({ lang: language });

  const create = useCallback(
    (event: { data: ScheduleDto }) => {
      const newSchedule: ScheduleDto = {
        userId: event.data.userId,
        clinicId: event.data.clinicId,
        startTime: event.data.startTime,
        endTime: event.data.endTime,
        intervalBetweenAppointments: event.data.intervalBetweenAppointments,
        usualInitialAppointmentDuration: event.data.usualInitialAppointmentDuration,
        usualSecondaryAppointmentDuration: event.data.usualSecondaryAppointmentDuration,
        workingHours: [],
      };

      createSchedule({
        scheduleDto: newSchedule,
      });
    },
    [createSchedule],
  );

  const onDelete = useCallback(
    (data: ScheduleDto) => {
      if (data.id) {
        deleteSchedule({
          scheduleId: data.id,
        });
      }
    },
    [deleteSchedule],
  );

  const updateScheduleFn = useCallback(
    (e: { data: ScheduleDto }) => {
      const { data } = e;
      if (data.id) {
        updateSchedule({
          scheduleId: data.id,
          scheduleDto: data,
        });
      }
    },
    [updateSchedule],
  );

  const calculateUserDisplayExpr = useCallback(
    (data: UserMultilang) => {
      const firstName = getTranslatedValue(data.firstName ?? [], language);
      const lastName = getTranslatedValue(data.lastName ?? [], language);
      return firstName + ' ' + lastName;
    },
    [language],
  );

  const calculateScheduleOwnerName = useCallback(
    (data: any) => {
      const user = allUsers?.find((user) => user.id === data.object.userId);
      const firstName = getTranslatedValue(user?.firstName ?? [], language);
      const lastName = getTranslatedValue(user?.lastName ?? [], language);
      return firstName + ' ' + lastName;
    },
    [allUsers, language],
  );

  const isLoading = useMemo(
    () =>
      isFetchingScheduleRevision ||
      isFetchingSchedules ||
      isCreatingSchedule ||
      isUpdatingSchedule ||
      isFetchingUsers ||
      isFetchingClinicsForCurrentUser ||
      isDeletingSchedule,
    [
      isCreatingSchedule,
      isFetchingClinicsForCurrentUser,
      isFetchingSchedules,
      isFetchingUsers,
      isUpdatingSchedule,
      isDeletingSchedule,
      isFetchingScheduleRevision,
    ],
  );

  const imgCellTemplate = useCallback(
    (container: any, options: any) => {
      const user = allUsers?.find((user) => user.id === options.data.userId);
      renderProfileImage(container, user?.profilePicture || null, user, language);
    },
    [allUsers, language],
  );

  const renderEyeButton = (data: any) => (
    <Button
      stylingMode="text"
      style={{ borderRadius: '50%' }}
      render={() => <FontAwesomeIcon icon="eye" />}
      onClick={(e) => {
        setScheduleId(data.id);
        setIsPopupVisible(true);
        e.event?.stopPropagation();
      }}
    />
  );

  const calculateRevisionTypeTranslation = useCallback(
    (data: ObjectRevisionDto) => {
      switch (data.revisionType) {
        case 'ADD':
          return t('toolTip.revisionPopup.ADD');
        case 'MOD':
          return t('toolTip.revisionPopup.MOD');
      }
    },
    [t],
  );

  const calculateRevisionDate = useCallback((data: any) => {
    const startDateTime = new Date(data.revisionDate);

    return startDateTime;
  }, []);

  const calculateStartTime = useCallback(
    (data: any) => {
      const result = new Date(data.object.startTime).toLocaleDateString(language, dateTimeOptions);
      return result;
    },
    [language],
  );

  const calculateEndTime = useCallback(
    (data: any) => {
      const result = new Date(data.object.endTime).toLocaleDateString(language, dateTimeOptions);
      return result;
    },
    [language],
  );

  const calculateUserName = useCallback(
    (data: ObjectRevisionDto) => {
      if (data.user === null) {
        return t('toolTip.revisionPopup.deletedUser');
      } else {
        return `${data.user?.firstName} ${data.user?.lastName}`;
      }
    },
    [t],
  );

  const calculateClinicName = useCallback(
    (data: { name: LocalizedStringDto[] }) => {
      return getTranslatedValue(data.name, language);
    },
    [language],
  );

  return (
    <>
      <LoadPanel
        visible={isLoading}
        position={{ my: 'center', at: 'center', of: '#scheduleDataGrid' }}
        shading={true}
        shadingColor={'rgba(0,0,0,.32)'}
        height="100%"
      />
      <Popup
        visible={isPopupVisible}
        onHiding={() => setIsPopupVisible(false)}
        showCloseButton={true}
        title={`${t('toolTip.revisionPopup.title')}`}
      >
        <ScrollView showScrollbar="always" direction="both">
          <DataGrid dataSource={scheduleRevision} columnAutoWidth={true}>
            <Column
              dataField={'revisionType'}
              caption={`${t('toolTip.revisionPopup.revisionType')}`}
              calculateCellValue={calculateRevisionTypeTranslation}
            />
            <Column
              dataField={'object.userId'}
              caption={`${t('schedulerDataGrid.user')}`}
              calculateCellValue={calculateScheduleOwnerName}
            />
            <Column
              dataField={'revisionDate'}
              caption={`${t('toolTip.revisionPopup.date')}`}
              calculateCellValue={calculateRevisionDate}
              dataType={'date'}
            />
            <Column
              dataField={'object.startTime'}
              caption={`${t('schedulerDataGrid.startTime')}`}
              calculateCellValue={calculateStartTime}
            />
    
            <Column
              dataField={'object.endTime'}
              caption={`${t('schedulerDataGrid.endTime')}`}
              calculateCellValue={calculateEndTime}
            />
            <Column
              dataField={'object.intervalBetweenAppointments'}
              caption={`${t('schedulerDataGrid.intervalBetweenAppointments')}`}
         
            />
            <Column
              dataField={'object.usualInitialAppointmentDuration'}
              caption={`${t('schedulerDataGrid.usualInitialAppointmentDuration')}`}
            />
            <Column
              dataField={'object.usualSecondaryAppointmentDuration'}
              caption={`${t('schedulerDataGrid.usualSecondaryAppointmentDuration')}`}
            />
            <Column
              dataField={'user'}
              caption={`${t('toolTip.revisionPopup.createdBy')}`}
              calculateCellValue={calculateUserName}
            />
          </DataGrid>
        </ScrollView>
      </Popup>
      <DataGrid
        id={'scheduleDataGrid'}
        height={'100%'}
        width={'100%'}
        columnAutoWidth={true}
        dataSource={cloneDeep(allSchedules)}
        onRowInserting={create}
        onRowUpdated={updateScheduleFn}
        onRowRemoving={(e) => onDelete(e.data)}
      >
        <FilterRow visible={true} />
        <LoadPanelProp enabled={false} />
        <Editing allowAdding={true} allowUpdating={true} allowDeleting={true} />
        <Column caption={''} cellTemplate={imgCellTemplate} allowFiltering={false} />
        <Column cellRender={({ data }) => renderEyeButton(data)} />{' '}
        <Column dataField={'userId'} caption={`${t('schedulerDataGrid.user')}`}>
          <Lookup dataSource={allUsers} valueExpr={'id'} displayExpr={calculateUserDisplayExpr} />
        </Column>
        <Column
          dataField={'startTime'}
          caption={`${t('schedulerDataGrid.startTime')}`}
          dataType={'date'}
          defaultSortOrder={'desc'}
        />
        <Column dataField={'endTime'} caption={`${t('schedulerDataGrid.endTime')}`} dataType={'date'} />
        <Column
          dataField={'intervalBetweenAppointments'}
          caption={`${t('schedulerDataGrid.intervalBetweenAppointments')}`}
        />
        <Column
          dataField={'usualInitialAppointmentDuration'}
          caption={`${t('schedulerDataGrid.usualInitialAppointmentDuration')}`}
        />
        <Column
          dataField={'usualSecondaryAppointmentDuration'}
          caption={`${t('schedulerDataGrid.usualSecondaryAppointmentDuration')}`}
        />
        <Column dataField={'clinicId'} caption={`${t('userDataGrid.clinics')}`}>
          <Lookup dataSource={allClinicsForCurrentUser} valueExpr={'id'} displayExpr={calculateClinicName} />
        </Column>
      </DataGrid>
    </>
  );
};
