import { DataGrid } from 'devextreme-react';
import { Column, Editing } from 'devextreme-react/data-grid';
import { LocalizedStringDto } from '../../api/medapp/country.generated';
import { t } from 'i18next';
import { useCallback } from 'react';

export interface LanguageDetailGridProps {
  names: LocalizedStringDto[]; // Existing translations
  onRowUpdating?: (oldData: LocalizedStringDto, newData: { value: string }) => void; // Callback for updates
  onRowInserting?: (newData: { value: string; language: string }) => void; // Callback for new insertions
}

export const LanguageDetailGrid = (props: LanguageDetailGridProps) => {
  const handleRowUpdating = useCallback((e: { oldData: LocalizedStringDto; newData: { value?: string } }) => {
    if (props.onRowUpdating) {
      const updatedData = {
        value: e.newData.value || e.oldData.value, // Default to oldData.value if newData.value is undefined
      };
      props.onRowUpdating(e.oldData, updatedData);
    }
  }, [props.onRowUpdating]);

  const handleRowInserting = useCallback((e: { data: { language?: string; value?: string } }) => {
    if (props.onRowInserting) {
      const newData = {
        language: e.data.language || '',
        value: e.data.value || '',
      };
      props.onRowInserting(newData);
    }
  }, [props.onRowInserting]);

  return (
    <DataGrid
      dataSource={props.names}
      onRowUpdating={handleRowUpdating} 
      onRowInserting={handleRowInserting} 
    >
      <Editing allowUpdating={true} allowAdding={true} /> 
      <Column dataField={'language'} caption={`${t('AddressForm.Language')}`} allowEditing={false}  />
      <Column dataField={'value'} caption={`${t('AddressForm.Value')}`} /> 
    </DataGrid>
  );
};
