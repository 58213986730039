import {
  Column,
  FilterRow,
  HeaderFilter,
  Toolbar,
  Item,
  Selection,
  LoadPanel as LoadPanelProp,
} from 'devextreme-react/data-grid';
import { DataGrid, LoadPanel } from 'devextreme-react';
import DataSource from 'devextreme/data/data_source';
import { useRef, useMemo, useCallback } from 'react';
import { ClinicMultiLang } from '../../api';
import { useDispatch } from 'react-redux';
import {
  DeleteClinicApiArg,
  useDeleteClinicMutation,
  useGetAllClinicsMultilangQuery,
} from '../../api/medapp/clinic.generated';
import { useGetAllOrganizationsMultiLangQuery } from '../../api/medapp/organization.generated';
import { useTranslation } from 'react-i18next';
import { EditClinicDialog } from './EditClinicDialog';
import { setClinicToEdit, toggleCreateClinicDialog } from '../../redux/modules/clinic';
import { custom as customDialog } from 'devextreme/ui/dialog';
import { getTranslatedValue } from '../helpers';
import { CreateClinicDialog } from './CreateClinicDialog';
import { createClinicDialogVisibleSelector } from '../../redux/modules/clinic';
import { useAppSelector } from '../../redux/store';
import { t } from 'i18next';

export const ClinicGrid = () => {
  const { i18n } = useTranslation();
  const clinicGridRef = useRef<DataGrid<ClinicMultiLang>>(null);
  const showAddClinicDialog = useAppSelector(createClinicDialogVisibleSelector);
  const dispatch = useDispatch();

  const { currentData: allClinics, isFetching: isFetchingClinics } = useGetAllClinicsMultilangQuery();
  const { currentData: allOrganizations, isFetching: isFetchingOrganizations } = useGetAllOrganizationsMultiLangQuery();

  const [deleteClinicMutation] = useDeleteClinicMutation();
  const deleteClinic = useCallback(
    (data: ClinicMultiLang) => {
      if (data.id) {
        deleteClinicMutation({ clinicId: data.id } as DeleteClinicApiArg);
      }
    },
    [deleteClinicMutation],
  );

  const onCreate = useCallback(() => {
    dispatch(toggleCreateClinicDialog());
  }, [dispatch]);

  const language = useMemo(() => i18n.language, [i18n.language]);

  const calculateClinicNameDisplayExpr = useCallback(
    (data: ClinicMultiLang | undefined) => {
      if (!data) return '';
      return data.name.find((name) => name.language === language)?.value || '';
    },
    [language],
  );

  const findOrganizationNameById = useCallback(
    (data: ClinicMultiLang) => {
      return (
        allOrganizations?.find((org) => org.id === data.organizationId)?.name.find((name) => name.language === language)
          ?.value || ''
      );
    },
    [language, allOrganizations],
  );

  const findAddressNameById = useCallback(
    (data: ClinicMultiLang) => {
      return getTranslatedValue(data.name, language);
    },
    [language],
  );

  const onEdit = useCallback(
    (selectedItems: ClinicMultiLang[] | undefined): void => {
      if (!selectedItems || selectedItems.length === 0) {
        customDialog({ message: 'No entry is selected' });
        return;
      }
      if (selectedItems.length > 1) {
        customDialog({ message: 'Editing multiple organizations is currently not supported' });
        return;
      }
      dispatch(setClinicToEdit(selectedItems[0]));
    },
    [dispatch],
  );

  const onDelete = useCallback(
    (selectedItems: ClinicMultiLang[] | undefined): void => {
      if (!selectedItems || selectedItems.length === 0) {
        customDialog({ message: 'No entry is selected' });
        return;
      }

      selectedItems
        .filter(Boolean) // Removes undefined values
        .forEach((clinic) => {
          if (clinic?.id) {
            const confirmDialog = customDialog({
              title: 'Confirm Deletion',
              messageHtml: 'Are you sure you want to delete this clinic?',
              buttons: [
                {
                  text: 'Yes',
                  onClick: () => {
                    deleteClinic(clinic);
                    return true;
                  },
                },
                {
                  text: 'No',
                  onClick: () => false,
                },
              ],
            });
            confirmDialog.show();
          }
        });
    },
    [deleteClinic],
  );

  return (
    <>
      {showAddClinicDialog && <CreateClinicDialog />}
      <EditClinicDialog />
      <LoadPanel
        visible={isFetchingClinics || isFetchingOrganizations}
        position={{ my: 'center', at: 'center', of: `#clinicDataGrid` }}
        shading={true}
        shadingColor={'rgba(0,0,0,.32)'}
        height="100%"
      />

      <DataGrid
        ref={clinicGridRef}
        id="clinicDataGrid"
        height="100%"
        width="100%"
        columnAutoWidth={true}
        repaintChangesOnly={true}
        dataSource={new DataSource(allClinics ?? [])}
      >
        <LoadPanelProp enabled={false} />
        <Selection mode="multiple" />
        <FilterRow visible={true} />
        <HeaderFilter visible={true} />
        <Toolbar>
          <Item
            widget="dxButton"
            visible={true}
            options={{
              icon: 'plus',
              onClick: onCreate,
            }}
            location="after"
          />
          <Item
            widget="dxButton"
            visible={true}
            options={{
              icon: 'edit',
              onClick: () => {
                onEdit(clinicGridRef.current?.instance.getSelectedRowsData());
              },
            }}
            location="after"
          />
          <Item
            widget="dxButton"
            visible={true}
            options={{
              icon: 'trash',
              onClick: () => {
                const selectedData = clinicGridRef.current?.instance.getSelectedRowsData();
                if (selectedData && selectedData.length > 0) {
                  onDelete(selectedData);
                } else {
                  customDialog({ message: 'Please select a clinic to delete' });
                }
              },
            }}
            location="after"
          />
        </Toolbar>
        <Column
          dataField="name"
          caption={`${t('navigationAdmin.clinicName')}`}
          calculateCellValue={calculateClinicNameDisplayExpr}
        />
        <Column dataField="phoneNumber" caption={`${t('createUserDialog.phone')}`} />
        <Column
          dataField="organizationId"
          caption={`${t('createUserDialog.organizationId')}`}
          calculateCellValue={findOrganizationNameById}
        />
        <Column
          dataField="addressId"
          caption={`${t('addressGrid.address')}`}
          calculateCellValue={findAddressNameById}
        />
      </DataGrid>
    </>
  );
};
